import { Box, Typography } from "@material-ui/core"
import React from "react"
import Background from "../../images/background-gradient.svg"

const TitledContentWrapper = ({ children, title, maxWidth }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt="140px"
    >
      <img
        style={{
          position: "absolute",
          zIndex: -999,
          width: "100vw",
          height: "59.5vw",
          top: "0",
        }}
        src={Background}
        alt="background"
      />
      <Box width="100%" display="flex" justifyContent="center" px="16px">
        <Box width="100%" maxWidth={maxWidth} display="flex" alignItems="start">
          <Box paddingY={4} display="flex">
            <Typography variant="h1">
              <span style={{ backgroundColor: "#f26522", padding: "0 8px" }}>
                {title}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
      {children}
    </Box>
  )
}

export default TitledContentWrapper
