import { Box, Button, Typography, makeStyles } from "@material-ui/core"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/layout-home"
import TitledContentWrapper from "../components/layout/titled-content-wrapper"
import { DigicLink } from "../components/digic-link"
import Headquarters from "../images/maps/digic_hq.png"
import MotionStudio from "../images/maps/digicmotion.png"
import Photoscan from "../images/maps/photoscan.png"
import MapIcon from "@material-ui/icons/Map"
import CameraIcon from "@material-ui/icons/Videocam"
import { SocialLinks } from "../components/layout/social-links"
import { ModalVideo } from "../components/utils/modal-video"

const useStyles = makeStyles(theme => ({
  map: {
    width: "400px",
    height: "400px",
  },
  socialIcon: {
    opacity: 0.8,
    marginLeft: theme.spacing(1),
    "&:hover": {
      opacity: 1,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
      marginLeft: 0,
    },
  },
  socialWrapper: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    marginTop: theme.spacing(1),
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "1280px",
    flexDirection: "row",
    padding: "0 24px",
    marginBottom: "64px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "0 24px",
    },
  },
  columnWrapper: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  columnWrapper2: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  caption: {
    color: [theme.palette.grey[80]],
  },
  data: {
    color: [theme.palette.primary.main],
    padding: "4px 0px",

    "&:hover": {
      color: "#fff",
    },
  },
  officeContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBottom: "64px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginBottom: "32px",
    },
  },
  addressTitle: {
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
    },
  },
}))

const ContactPage = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    {
      strapi {
        contactPage {
          ContactTitle
          MailTitle
          Emails {
            EmailAddress
            EmailTitle
          }
          OfficeTitle
          OfficeAddressTitle
          OfficeAdress
          PhoneTitle
          PhoneSubtitle
          Phones {
            PhoneNumberTitle
            PhoneNumber
          }
          FaxTitle
          FaxNumber
          FaxNumberTitle
        }
      }
    }
  `)

  const addresses = [
    {
      id: 1,
      title: "DIGIC Headquarters",
      map: Headquarters,
      address: data.strapi.contactPage.OfficeAdress,
      link:
        "https://www.google.hu/maps/place/Digic+Pictures/@47.5623611,19.0561183,17z/data=!3m1!4b1!4m6!3m5!1s0x4741d983b64e599f:0x760849b5c895b305!8m2!3d47.5623611!4d19.0561183!16s%2Fg%2F11clsr0j__",
      video: "https://www.youtube.com/embed/k2BenOl_iIU",
      open: false,
    },
    {
      id: 2,
      map: Photoscan,
      title: "DIGIC Photoscan studio",
      address: "BUDAPEST, H-1031 GRAPHISOFT PARK HY. ZÁHONY UTCA 7.",
      link:
        "https://www.google.hu/maps/place/Digic+Pictures/@47.5623611,19.0561183,17z/data=!3m1!4b1!4m6!3m5!1s0x4741d983b64e599f:0x760849b5c895b305!8m2!3d47.5623611!4d19.0561183!16s%2Fg%2F11clsr0j__",
      video: "https://www.youtube.com/embed/zcY2ghzjV24",
      open: false,
    },
    {
      id: 3,
      map: MotionStudio,
      title: "DIGIC Motion",
      address: "Dunakeszi, H-2120 Pallag u 17.",
      link:
        "https://www.google.hu/maps/place/Dunakeszi,+Pallag+u+17,+2120/@47.6150381,19.1115892,15.43z/data=!4m6!3m5!1s0x4741d0c6446d0f2b:0x17d22af8ee5c9c8b!8m2!3d47.6132529!4d19.118218!16s%2Fg%2F11m_l4pjv3?entry=ttu",
      video: "https://www.youtube.com/embed/sn3TcnYVbVQ",
      open: false,
    },
  ]

  const [selectedVideo, setSelectedVideo] = React.useState(null)

  const handleOpenModal = video => {
    setSelectedVideo(video)
  }

  const handleCloseModal = () => {
    setSelectedVideo(null)
  }

  return (
    <Layout>
      <TitledContentWrapper
        title={data.strapi.contactPage.ContactTitle}
        maxWidth="1240px"
      >
        <Box className={classes.contentWrapper}>
          <Box className={classes.columnWrapper}>
            <Typography
              variant="h3"
              style={{ paddingBottom: "16px", textTransform: "capitalize" }}
            >
              {data.strapi.contactPage.MailTitle}
            </Typography>
            {data.strapi.contactPage.Emails.map(
              ({ EmailAddress, EmailTitle }) => (
                <Box mb={3} key={EmailAddress}>
                  <Typography variant="caption" className={classes.caption}>
                    {EmailTitle}
                  </Typography>
                  <a
                    style={{ textDecoration: "initial" }}
                    href={`mailto: ${EmailAddress}`}
                  >
                    <Typography variant="h4" className={classes.data}>
                      {EmailAddress}
                    </Typography>
                  </a>
                </Box>
              )
            )}
          </Box>
          <Box className={classes.columnWrapper}>
            <Box mb={2}>
              <Typography
                variant="h3"
                style={{ paddingBottom: "16px", textTransform: "capitalize" }}
              >
                {data.strapi.contactPage.PhoneTitle}
              </Typography>
              {data.strapi.contactPage.Phones.map(
                ({ PhoneNumberTitle, PhoneNumber }) => (
                  <Box mb={3}>
                    <Typography variant="caption" className={classes.caption}>
                      {PhoneNumberTitle}
                    </Typography>
                    <a
                      style={{ textDecoration: "initial" }}
                      href={`tel: ${PhoneNumber}`}
                    >
                      <Typography variant="h4" className={classes.data}>
                        {PhoneNumber}
                      </Typography>
                    </a>
                    <Typography variant="body2" className={classes.caption}>
                      {data.strapi.contactPage.PhoneSubtitle}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
            <Box mb={2}>
              <Typography
                variant="h3"
                style={{ paddingBottom: "16px", textTransform: "capitalize" }}
              >
                {data.strapi.contactPage.FaxTitle}
              </Typography>
              <Box mb={3}>
                <Typography variant="caption" className={classes.caption}>
                  {data.strapi.contactPage.FaxNumberTitle}
                </Typography>
                <a
                  style={{ textDecoration: "initial" }}
                  href={`fax: ${data.strapi.contactPage.FaxNumber}`}
                >
                  <Typography variant="h4" className={classes.data}>
                    {data.strapi.contactPage.FaxNumber}
                  </Typography>
                </a>
              </Box>
            </Box>
            <Box mb={2}>
              <Typography variant="h3" style={{ paddingBottom: "16px" }}>
                Find us on social media
              </Typography>
              <SocialLinks justifyContent="start" />
            </Box>
          </Box>
        </Box>
        <Box className={classes.contentWrapper}>
          <Box display="flex" flexDirection="column" width="100%">
            <Typography variant="h2" style={{ paddingBottom: "64px" }}>
              Get to know each other in person
            </Typography>
            {addresses.map(address => (
              <Box className={classes.officeContainer} key={address.id}>
                <Box width="100%" mb={3}>
                  <Typography variant="h3" className={classes.addressTitle}>
                    {address.title}
                  </Typography>
                  <a
                    style={{ textDecoration: "initial" }}
                    href={address.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="caption" className={classes.caption}>
                      {address.address}
                    </Typography>
                  </a>
                  <a
                    style={{
                      textDecoration: "initial",
                      display: "flex",
                      marginTop: "32px",
                    }}
                    href={address.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MapIcon
                      color="primary"
                      style={{ fontSize: 24, margin: " 4px 8px 4px 0px" }}
                    />
                    <Typography variant="h4" className={classes.data}>
                      Map link
                    </Typography>
                  </a>
                  <Button
                    style={{
                      textDecoration: "initial",
                      display: "flex",
                      margin: "0",
                      padding: "0",
                    }}
                    onClick={() => handleOpenModal(address)}
                  >
                    <CameraIcon
                      color="primary"
                      style={{ fontSize: 24, margin: " 4px 8px 4px 0px" }}
                    />
                    <Typography variant="h4" className={classes.data}>
                      how to get there?
                    </Typography>
                  </Button>
                  <ModalVideo
                    videoSrc={selectedVideo?.video}
                    onClose={handleCloseModal}
                    open={selectedVideo?.id === address.id}
                  />
                </Box>
                <Box>
                  <DigicLink
                    href={address.link}
                    target="_blank"
                    style={{ width: "100%", maxWidth: 1500 }}
                  >
                    <img
                      alt="map"
                      src={address.map}
                      style={{ width: "100%", maxWidth: 1500 }}
                    />
                  </DigicLink>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </TitledContentWrapper>
    </Layout>
  )
}

export default ContactPage
