import { Box, Modal } from "@material-ui/core"
import React from "react"

export const ModalVideo = ({ videoSrc, open, onClose }) => {
  const handleBackdropClick = () => {
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleBackdropClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
        onClick={handleBackdropClick}
      >
        <iframe
          src={videoSrc}
          title="Cozy Jazz House Mix - Amii Watson B2B Jimmi Harvey"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          style={{ width: "80vw", height: "45vw" }}
        />
      </Box>
    </Modal>
  )
}
